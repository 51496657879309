<template>
  <b-card-code
    no-body
    title="Order Paket Talenta Jagoan"
  >
    <table-order-talenta-jagoan
      :result="result"
      :is-loading="isLoading"
      :get-order-data="getData"
      :approve-reject="approveReject"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import TableOrderTalentaJagoan from '@/components/OrderTalentaJagoan/TableOrderTalentaJagoan.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'List Paket Talenta Jagoan'
  },
  components: {
    BCardCode,
    TableOrderTalentaJagoan,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      result: {},
      isLoading: false,
      validations: {},
      formPayload: Object,
      editId: null,
      filter: {},
    }
  },
  created() {
    this.getData()
  },
  methods: {
    approveReject(uuid, type) {
      this.$http.post(`admin/talenta-jagoan/order/${uuid}/${type}`)
        .then(response => {
          this.getData()
          successNotification(this, 'Success', `Order successfully ${type}`)
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.validations.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.validations)
          }
        })
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/talenta-jagoan/order', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">

</style>
